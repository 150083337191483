import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import GatsbyImage from 'gatsby-image'
import { HeadingContainer, Text } from '../components/common/typography'
import AppLayout from '../components/layouts/appLayout'
import bp from '../components/common/breakpoints'
import { BLACK_COLOR_RGB, WHITE_COLOR } from '../components/common/color'
import ChalkboardSVG from '../images/icons/services/chalkboard.svg'
import ChartSVG from '../images/icons/services/chart.svg'
import DevicesSVG from '../images/icons/services/devices.svg'
import DiamondSVG from '../images/icons/services/diamond.svg'
import ExtensionSVG from '../images/icons/services/extension.svg'
import RocketSVG from '../images/icons/services/rocket.svg'
import SEO from '../components/seo'

const services = [
  {
    icon: <ChartSVG />,
    title: 'Strategic Consulting',
    description:
      'We help you identify opportunities for improvement and with our engineering expertise help come up with tailor made solutions. Rely on our team oriented attitude and problem solving skills to make turn your ideas into reality.',
  },
  {
    icon: <RocketSVG />,
    title: 'Start Up Tech Partner',
    description:
      'Let us be part of the Magic! We can augment your Tech staff be it Software Devs, QA Devs, DevOps,etc to help you build the future.',
  },
  {
    icon: <DevicesSVG />,
    title: 'Software Development',
    description:
      'We build mobile, web, cloud apps for your business and guide you with our batch of experts to take the best and fastest path towards monetization for your application/business.',
  },
  {
    icon: <ChalkboardSVG />,
    title: 'Agile Project Management',
    description:
      'We are fans of Agility and practice what we preach. Following traditional SCRUM and also not so traditional with a brand of shaping up and delivering your product in cycles that make sense to your investors.',
  },
  {
    icon: <DiamondSVG />,
    title: 'Manufacturing and Mining Solutions',
    description:
      'Leverage our locally developed engineers in the fields of Manufacturing and Mining with Software to help bring your business to the next era in digitalization.',
  },
  {
    icon: <ExtensionSVG />,
    title: 'Creative Solutions',
    description:
      'From UI design, graphics, animation, AR/VR, we use our skills to fulfill your business vision to attract more customers/business.',
  },
]

const Content = styled.div`
  position: relative;
  padding-top: 2rem;
`
const BackgroundIMG = styled.div`
  position: absolute;
  top: 14vh;
  width: 100%;
  height: 66vh;
  z-index: -1;

  ${bp[2]}{
    height:380px;
  }
`

const BackgroundFixed = styled.div`
  position: fixed;
  width: 100%;
  height: 66vh;
  z-index: -1;
  overflow: hidden;

  ${bp[2]}{
    height:380px;
  }
`

const CardContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fill, 1fr);
  row-gap: 1.5rem;
  column-gap: 1.5rem;
  align-items: center;
  justify-items: center;
  justify-content: center;
  width: 90%;
  margin: 0 auto;
  padding-bottom: 4rem;

  ${bp[1]} {
    grid-template-columns: repeat(auto-fill, minmax(360px, 1fr));
  }
`

const Card = styled.div`
  display: grid;
  grid-template-rows: 2fr auto 3fr;
  row-gap: 1rem;
  max-width: 300px;
  min-height: 300px;
  padding: 1rem;
  background: ${WHITE_COLOR};
  box-sizing: border-box;
  box-shadow: 10px 5px 10px ${BLACK_COLOR_RGB(0.2)}, -10px -10px 20px #ffffff;
  border-radius: 20px;

  ${bp[1]} {
    grid-template-rows: 1fr auto 1fr;
    max-width: unset;
    min-width: 360px;
    min-height: 360px;
    padding: 2rem;
  }
`

const CardIcon = styled.div`
  justify-self: center;
  align-self: center;
  margin-bottom: 0.5rem;
`

const CardTitle = styled.h4`
  margin: 0;
  text-transform: uppercase;
  font-weight: 700;
  color: ${BLACK_COLOR_RGB(0.8)};
`

const CardDescription = styled(Text)`
  font-weight: 400;
  color: ${BLACK_COLOR_RGB(0.5)};
`

const ServicesPage = ({ data }) => {
  const { backgroundDesktop, backgroundMobile } = data

  const sources = [
    backgroundMobile.fluid,
    {
      ...backgroundDesktop.fluid,
      media: `(min-width: 768px)`,

    },
  ]

  return (
    <AppLayout>
      <SEO title="Services" canonical="Services" description="Software development & Software engineering services" />
      <Content>
        <HeadingContainer title="We specialize in transforming your ideas into digital solutions" />
        <BackgroundIMG>
          <BackgroundFixed>
            <GatsbyImage fluid={sources} style={{ borderRadius: '4px', opacity: '0.9' }} />
          </BackgroundFixed>
        </BackgroundIMG>
        <CardContainer>
          {services.map((service) => (
            <Card key={service.title.toLocaleLowerCase().replace(' ', '-')}>
              <CardIcon>{service.icon}</CardIcon>
              <CardTitle>{service.title}</CardTitle>
              <CardDescription>{service.description}</CardDescription>
            </Card>
          ))}
        </CardContainer>
      </Content>
    </AppLayout>
  )
}

export const query = graphql`
  query {
    backgroundMobile: imageSharp(
      fluid: { originalName: { eq: "Rectangle-65.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100 , maxWidth: 768, maxHeight:768) {
        ...GatsbyImageSharpFluid
      }
    }
    backgroundDesktop: imageSharp(
      fluid: { originalName: { eq: "services-background.jpg" } }
    ) {
      fluid(jpegQuality: 100, quality: 100 , maxWidth: 1400, maxHeight: 320) {
        ...GatsbyImageSharpFluid
      }
    }
  }
`

export default ServicesPage
